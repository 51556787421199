<template>
	<main>
		<HeaderTab :title="$t('global.juments_non_suivies')"  :return_action="'navigationBack'"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<SeasonInput v-model="seasonSelected" @change="changeSeason" />

							<template v-if="seasonSelected != null">
								<CustomTable
									v-if="items.length > 0"
									ref="horse_unfollowed_table"
									id_table="horse_unfollowed"
									:items="items"
									:busy.sync="table_busy"
									primaryKey="horse.horse_id"
									:hrefsRoutes="config_table_hrefs"
									:hide_if_empty="true"
									:base-filters="filters"
								/>
								<LoadingSpinner v-else class="col-12" />
							</template>

							<ModalPrintInfosHorse ref="modal_print_infos_horse"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
import Horse from '@/mixins/Horse.js'
import Navigation from "@/mixins/Navigation.js"

export default {
	name: "JumentsNonSuivies",
	mixins: [Horse, Navigation],
	data () {
		return {
			items: [],
			horses: [],
			table_busy: false,
			seasonSelected: null,
			filters: {},
			config_table_hrefs: {
				'horse.horse_nom': {
					routeUniqueName: 'horseFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse.horse_id'
					}
				}
			},
			events_tab: {
				'TableAction::goToPrintInfosHorse': (params) => {
					this.openModalPrintInfosHorse(params.horse_ids)
				},
				'TableAction::DeleteHorses': this.deleteHorses,
			},
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			if(!this.seasonSelected) return

			this.items = await this.loadMaresNotFollowed(this.seasonSelected.id)

			this.filters = {
				residence: {
					column: 'horse.horse_residence_label',
					operator: 'isNotEmpty',
				},
				horse_not_archived: {
					column: 'horse.horse_inactive',
					operator: 'isEqualTo',
					value: 0
				}
			}
		},

		async changeSeason() {
			if(!this.seasonSelected) return

			this.init_component()
		},

		async deleteHorses(horses) {
			const ids = horses.map(horse => horse.horse_id)
			
			this.devalideHorses(ids)
				.then(() => {
					this.$refs.horse_unfollowed_table.refreshTable()
				})
		},
		openModalPrintInfosHorse(horse_ids) {
			this.$refs.modal_print_infos_horse.openModal(horse_ids)
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		SeasonInput : () => import('@/components/Inputs/SeasonInput'),
		ModalPrintInfosHorse : () => import('@/components/Horse/ModalPrintInfosHorse'),
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>
